.dashboard-page {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;

  .action-history-container {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
  }

  .top-container {
    flex: 1;
    width: 100%;
    padding: 10px;
    overflow-y: scroll;
  }

  .dashboard-arranger {
    flex: 1;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .dashboard-top {
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      overflow-y: scroll;

      .dashboard-top-left {
        height: 100%;
        flex: 1;
        overflow-y: scroll;
        .dashboard-list-container {
          padding: 20px;
        }
      }
      .dashboard-top-right {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 20px;
        padding-left: 0;
        width: 230px;
        height: 100%;
        overflow-y: scroll;
      }
    }
    .dashboard-bottom {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      overflow-x: scroll;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      min-height: 212px;
    }
  }

}

.dashboard-status-select-grid {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 10px;
}

.dashboard-status-select-grid-entry-improved {
  display: flex;
  align-items: center;
  width: 16.666%;
  font-size: $sizeFontNormal;
  .dashboard-status-select-grid-entry-improved-container {
    padding: 10px;
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
      .dashboard-status-select-grid-entry-improved-title {
        text-decoration: underline;
      }
    }
    .dashboard-status-select-grid-entry-improved-title {
      padding-left: 4px;
    }
    .dashboard-status-select-grid-entry-improved-count {
      padding-left: 4px;
      font-weight: 500;
    }
  }
}

.dashboard-status-select-grid-entry {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;

  .inner-container {

    width: 100%;
    flex:1;
    background-color: #ccc;
    border-radius: $sizeBorderRadiusSmall;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .inner-container-for-hover {
      width: 100%;
      height: 100%;
      border-top-left-radius: $sizeBorderRadiusSmall;
      border-top-right-radius: $sizeBorderRadiusSmall;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      .first-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: flex-end;
        flex: 1;
      }
      .inner-title {
        color: $colorWhite;
        font-size: $sizeFontNormal;
        padding-bottom: 10px;
        padding-left: 10px;
      }
      .inner-count {
        color: $colorWhite;
        font-size: $sizeFontExtraLarge;
        padding: 20px;
        padding-left: 25px;
        background-color: #ccc;
        border-radius: 100px;

        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        margin-bottom: 20px;
      }
      .inner-description {
        color: #ccc;
        font-size: $sizeFontExtraSmall;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
        text-align: left;
      }
    }
    .another-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 10px 10px;
      .another-container-inactive {
        color: #888;
        padding: 10px;
        font-size: $sizeFontSmall;
      }
      .another-container-active {
        font-size: $sizeFontSmall;
        color: $colorMainDarkOther;
        border-radius: $sizeBorderRadiusSmall;
        transition-duration: $transitionDurationNormal;
        padding: 10px;
        cursor: pointer;
      }
      .another-container-active:hover {
        background-color: #b0b0b0;
      }
    }
  }
}

.analytics_card_dashboard {
  p {
    font-size: 14px;
    font-weight: 600;
  }
  .stats_icon {
    background: rgba(255,255,255,0.4);
    transition-duration: $transitionDurationNormal;
    padding: 2px 10px;
    border-radius: 50px;
    margin-left: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .stats_icon:hover {
    background: rgba(255,255,255,0.2);
  }
  .icon-rounded {
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 50%;
    text-align: center;
    background: white;
    i {
      font-size: 1.625rem;
      line-height: 3.75rem;
    }
  }
}

.advanced-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 10px;

  color: #333333;
  border-color: $colorMainDarker;
  border-width: 1px;
  background-color: $colorMainDiffused08;
  cursor: pointer;

  transition-duration: $transitionDurationNormal;
  padding: 10px;
  padding-left: 20px;

  .icon-container {

  }

  .text-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    padding-left: 20px;
    align-items: flex-start;
    .hint-container {
      font-size: $sizeFontSmall;
      color: #111;
    }
    .label-container {
      font-size: $sizeFontSmall;
      color: #333;
    }
    .description-container {
      font-size: $sizeFontExtraSmall;
      color: #333;
      padding-top: 4px;
    }
  }
}

.advanced-button:hover {
  background-color: $colorMainDiffused16;
}