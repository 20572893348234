@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900");
/* ============================ */
/*            Preloader         */
/* ============================ */
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 9999;
}
.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  margin: 0 auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

/* End Preloader */

img {
  margin: 0;
}

/* links */

a,
a:visited {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  outline: 0;
  /*font-family: "Poppins", sans-serif;*/
}
a:hover {
  text-decoration: none;
}
a:focus {
  text-decoration: none;
  outline: 0;
}

p a,
p a:visited {
  line-height: inherit;
  outline: 0;
}

/* list */

ul,
ol {
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 0;
}

ul {
  margin: 0;
  list-style-type: none;
}

ol {
  list-style: decimal;
}

ol,
ul.square,
ul.circle,
ul.disc {
  margin-left: 0px;
}

ul.square {
  list-style: square outside;
}

ul.circle {
  list-style: circle outside;
}

ul.disc {
  list-style: disc outside;
}

ul ul,
ul ol,
ol ol,
ol ul {
  margin: 0;
}

ul ul li,
ul ol li,
ol ol li,
ol ul li {
  margin-bottom: 0px;
}

button {
  cursor: pointer;
  outline: none !important;
  letter-spacing: 0;
}

/*------------------------- 1. Core Css -------------------*/

$sidebarTransitionContainer: padding-left 0.3s ease 0s;

.page-container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-left: $drawerOpenSize;
  -webkit-transition: $sidebarTransitionContainer;
  transition: $sidebarTransitionContainer;
}

.sidebar_collapsed.page-container {
  padding-left: $drawerClosedSize;
}

.main-content {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #fafafa;
}

.main-content-inner {
  padding: 0;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  flex:1;
}

.main-content-inner-padded {
  flex:1;
  padding: 20px;
  @media (max-width: 992px) {
    padding: 10px 8px;
  }
}

/*------------------------- END Core Css -------------------*/

.footer-area {
  text-align: center;
  padding: 23px 0 19px;
  background: #fff;
}

.footer-area p {
  color: #676666;
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .d_block_lg {
    display: block !important;
  }
  .d_none_lg {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .d_none_sm {
    display: none !important;
  }
  .d_block_sm {
    display: block !important;
  }
}

/*Medium Desktop Device*/

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .col-ml-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-ml-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-ml-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-ml-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

/* ============================= */
/*           Fancy Box           */
/* ============================= */

.fancybox-next span,
.fancybox-prev span {
  background-image: none !important;
  width: 44px !important;
  height: 44px !important;
  line-height: 44px !important;
  text-align: center;
}

.fancybox-next span:before,
.fancybox-prev span:before {
  content: "";
  position: absolute;
  font-family: "FontAwesome";
  left: 0px;
  top: 0px;
  font-size: 12px;
  width: 44px !important;
  height: 44px !important;
  line-height: 44px !important;
  background-color: rgba(28, 28, 28, 0.4) !important;
  color: #ffffff;
  visibility: visible;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
}

.fancybox-next span:before {
  content: "\f178";
}

.fancybox-prev span:before {
  content: "\f177";
}

.fancybox-next:hover span:before,
.fancybox-prev:hover span:before {
  background: $shark !important;
  color: $white;
}

.fancybox-type-image .fancybox-close {
  right: 0px;
  top: 0px;
  width: 45px;
  height: 45px;
  background: url("../../images/icon-cross.png") center center no-repeat;
  background-color: $shark !important;
}

.fancybox-type-image .fancybox-close:hover {
  background-color: #000000 !important;
}

.fancybox-type-image .fancybox-skin {
  padding: 0px !important;
}

.section_heading {
  font-size: 40px;
  margin-bottom: 60px;
  position: relative;
  display: inline-block;
  @media only screen and (max-width: 480px) {
    font-size: 25px !important;
  }
  .heading_bg {
    top: 30px;
    color: #666;
    font-size: 70px;
    width: 100%;
    display: block;
    position: relative;
    opacity: 0.1;
    @media only screen and (max-width: 767px) {
      font-size: 50px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 25px;
    }
  }
}

/* ============================ */
/*            Card              */
/* ============================ */

.card {
  border: none;
  border-radius: 4px;
  background-color: #fff;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.03), 0 1px 4px rgba(0, 0, 0, 0.02);
  &:hover {
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.02);
  }
  .card-body {
    padding: 25px;
  }
  .card_title {
    margin-bottom: 24px;
    text-transform: uppercase;
    color: #212529;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }
  .card-footer {
    border-top: 1px solid #e6e6f2;
    background: #f6f6ff;
  }
}

.h-full {
  height: 100%;
}

//Strech Card
.stretched_card {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
  align-items: stretch;
  justify-content: stretch;
  > .card {
    width: 100%;
    min-width: 100%;
  }
}

// Custom Check Box

.custom_checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 0;

  .custom_check_label {
    min-height: 18px;
    display: block;
    margin-left: 1.75rem;
    font-size: $default-font-size;
    line-height: 1.5;
    .rtl & {
      margin-left: 0;
      margin-right: 1.75rem;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      .rtl & {
        left: auto;
        right: 0;
      }
      margin-left: 0;
      margin-top: 0;
      z-index: 1;
      cursor: pointer;
      opacity: 0;
      filter: alpha(opacity=0);
    }
    input[type="checkbox"] {
      + .input-helper {
        &:before,
        &:after {
          position: absolute;
          top: 0;
          left: 0;
          .rtl & {
            left: auto;
            right: 0;
          }
        }
        &:before {
          content: "";
          width: 20px;
          height: 20px;
          border-radius: 5px;
          border: solid #eaeaea;
          border-width: 2px;
          -webkit-transition-duration: 250ms;
          transition-duration: 250ms;
        }

        &:after {
          -webkit-transition-duration: 250ms;
          transition-duration: 250ms;
          font-family: Ionicons;
          font-style: normal;
          opacity: 0;
          filter: alpha(opacity=0);
          -webkit-transform: scale(0);
          -ms-transform: scale(0);
          -o-transform: scale(0);
          transform: scale(0);
          content: "\f3fd";
          font-weight: bold;
          color: $white;
          left: 4px;
          font-size: 26px;
          top: 1px;
        }
      }
      &:checked {
        + .input-helper {
          &:before {
            background: #4327b7;
            border-width: 0;
          }
          &:after {
            width: 18px;
            opacity: 1;
            line-height: 18px;
            filter: alpha(opacity=100);
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            -o-transform: scale(1);
            transform: scale(1);
          }
        }
      }

      &:disabled {
        + .input-helper {
          &:before {
            border-color: #eaeaea;
          }
        }

        &:checked {
          + .input-helper {
            &:after {
              background: theme-color-main(primary);
              color: #eaeaea;
            }
          }
        }
      }
    }
    input[type="radio"] {
      + .input-helper {
        &:before {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          .rtl & {
            left: auto;
            right: 0;
          }
          border: solid #eaeaea;
          border-width: 2px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          -webkit-transition-duration: 250ms;
          transition-duration: 250ms;
        }

        &:after {
          content: "";
          width: 8px;
          height: 8px;
          color: $white;
          background: #4327b7;
          border-radius: 50%;
          top: 6px;
          left: 6px;
          .rtl & {
            left: auto;
            right: 6px;
          }
          -webkit-transition: all;
          -o-transition: all;
          transition: all;
          -webkit-transition-duration: 250ms;
          transition-duration: 250ms;
          opacity: 0;
          filter: alpha(opacity=0);
          -webkit-transform: scale(0);
          -ms-transform: scale(0);
          -o-transform: scale(0);
          transform: scale(0);
          position: absolute;
        }
      }

      &:checked {
        + .input-helper {
          &:before {
            background: #4327b7;
            border: 2px solid theme-color-main(primary);
          }

          &:after {
            opacity: 1;
            line-height: 1.5;
            filter: alpha(opacity=100);
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            -o-transform: scale(1);
            transform: scale(1);
          }
        }
      }

      &:disabled {
        + .input-helper {
          &:before {
            border-color: #eaeaea;
          }
        }

        &:checked {
          + .input-helper {
            &:before {
              background: #4327b7;
            }

            &:after {
              background: #eaeaea;
            }
          }
        }
      }
    }
  }
}

// Charts General
.chart_container {
  height: 350px;
}

// Carousel

.carousel {
  img {
    width: 100%;
  }
}

/* Ace Editor */
.ace_editor {
  margin: auto;
  height: 300px;
  width: 100%;

  .ace_content {
    font-size: $default-font-size;
  }
}

.card_dropdown {
  .dropdown-menu {
    font-size: 14px;
    text-align: right;
    text-transform: initial;
  }
}

#morris_line {
  font-family: "Montserrat" !important;
}

// Responsive Styling

@media screen and (max-width: 991px) {
  .d-block-mob {
    display: block !important;
  }
  // Margin Top
  .mt-mob-0 {
    margin-top: 0 !important;
  }
  .mt-mob-1 {
    margin-top: 0.25rem !important;
  }
  .mt-mob-2 {
    margin-top: 0.5rem !important;
  }
  .mt-mob-3 {
    margin-top: 1rem !important;
  }
  .mt-mob-4 {
    margin-top: 1.5rem !important;
  }
  .mt-mob-5 {
    margin-top: 3rem !important;
  }

  // Margin Right
  .mr-mob-0 {
    margin-right: 0 !important;
  }
  .mr-mob-1 {
    margin-right: 0.25rem !important;
  }
  .mr-mob-2 {
    margin-right: 0.5rem !important;
  }
  .mr-mob-3 {
    margin-right: 1rem !important;
  }
  .mr-mob-4 {
    margin-right: 1.5rem !important;
  }
  .mr-mob-5 {
    margin-right: 3rem !important;
  }

  // Margin Bottom
  .mb-mob-0 {
    margin-bottom: 0 !important;
  }
  .mb-mob-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-mob-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-mob-3 {
    margin-bottom: 1rem !important;
  }
  .mb-mob-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-mob-5 {
    margin-bottom: 3rem !important;
  }
  // Margin Left
  .ml-mob-0 {
    margin-left: 0 !important;
  }
  .ml-mob-1 {
    margin-left: 0.25rem !important;
  }
  .ml-mob-2 {
    margin-left: 0.5rem !important;
  }
  .ml-mob-3 {
    margin-left: 1rem !important;
  }
  .ml-mob-4 {
    margin-left: 1.5rem !important;
  }
  .ml-mob-5 {
    margin-left: 3rem !important;
  }

  //Padding

  // Padding Top
  .pt-mob-0 {
    padding-top: 0 !important;
  }
  .pt-mob-1 {
    padding-top: 0.25rem !important;
  }
  .pt-mob-2 {
    padding-top: 0.5rem !important;
  }
  .pt-mob-3 {
    padding-top: 1rem !important;
  }
  .pt-mob-4 {
    padding-top: 1.5rem !important;
  }
  .pt-mob-5 {
    padding-top: 3rem !important;
  }

  // Padding Right
  .pr-mob-0 {
    padding-right: 0 !important;
  }
  .pr-mob-1 {
    padding-right: 0.25rem !important;
  }
  .pr-mob-2 {
    padding-right: 0.5rem !important;
  }
  .pr-mob-3 {
    padding-right: 1rem !important;
  }
  .pr-mob-4 {
    padding-right: 1.5rem !important;
  }
  .pr-mob-5 {
    padding-right: 3rem !important;
  }

  // Padding Bottom
  .pb-mob-0 {
    padding-bottom: 0 !important;
  }
  .pb-mob-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-mob-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-mob-3 {
    padding-bottom: 1rem !important;
  }
  .pb-mob-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-mob-5 {
    padding-bottom: 3rem !important;
  }
  // Padding Left
  .pl-mob-0 {
    padding-left: 0 !important;
  }
  .pl-mob-1 {
    padding-left: 0.25rem !important;
  }
  .pl-mob-2 {
    padding-left: 0.5rem !important;
  }
  .pl-mob-3 {
    padding-left: 1rem !important;
  }
  .pl-mob-4 {
    padding-left: 1.5rem !important;
  }
  .pl-mob-5 {
    padding-left: 3rem !important;
  }
}

// Social Media
.social-sales-icon-circle {
  height: 40px;
  width: 40px;
  line-height: 1;
  text-align: center;
  border-radius: 100%;
  padding: 12px 13px;
  display: inline-block;
}
.social-sales-count {
  float: right;
  line-height: 2.9;
}

// Social Media Colors
.facebook-bgcolor {
  background-color: #3b5898;
  color: #fff;
}
.twitter-bgcolor {
  background-color: #1ea2f2;
  color: #fff;
}
.instagram-bgcolor {
  background-color: #9361fa;
  color: #fff;
}
.pinterest-bgcolor {
  background-color: #c8232c;
  color: #fff;
}
.googleplus-bgcolor {
  background-color: #d34836;
  color: #fff;
}

.carousel-caption {
  h3 {
    color: #fff;
  }
}
.is_blank_page {
  height: 100vh;
}

.rdw-editor-main,
.ql-editor {
  border: 1px solid #f1f1f1 !important;
  border-radius: 4px !important;
  min-height: 230px !important;
  padding: 0 20px !important;
}

#googlemap_container {
  height: 400px;
  background: #f8f9fa;
  border-radius: 3px;
}

.ql-editor {
  padding: 20px !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: transparent !important;
}
.form-control {
  height: auto !important;
}

.datatable_container .dataTables_paginate {
  float: right;
  @media screen and (max-width: 991px) {
    float: none;
    margin-top: 20px;
  }
}

.home_map {
  height: 370px;
}





.list-page {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;

  .header-elements {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    overflow-x: scroll;
    width: 100%;
    height: 1500px;
    padding: 20px;
    overflow-y: hidden;
    background-color: #333333;
  }

  .list-container {
    width: 100%;
    overflow-y: scroll;
    padding: 0;
  }

}

.list-table-container {
  width: 100%;
  overflow-y: scroll;
  padding: 0;
  .list-table {
    width: 100%;
    table-layout: fixed;
    padding: 0 0;
  }
}

.list-page-dashboard {
  @extend .list-page;

  .dashboard-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    .dashboard-main-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
      .dashboard-list-container {
        flex: 1;
        width: 100%;
      }
    }
    .dashboard-actions-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

}

.table-list-item-row {
  width: 100%;
  background-color: #fafafa;
  transition-duration: 0.1s;
  border-left: solid 10px #fafafa;
  border-right: solid 10px #fafafa;
  .table-list-item-cell {

  }
  .table-list-item-cell-title {
    font-weight: 700;
  }
  .table-list-item-cell-action {
    cursor: pointer;
  }
  .table-list-item-cell-action:hover {
    text-decoration: underline;
  }
}

.table-list-item-row:hover {
  background-color: #eaeaea;
  border-color: #544c4c;
}

.table-list-item-row:nth-child(even) {
  background-color: #dddddd;
  border-left: solid 10px #dddddd;
  border-right: solid 10px #dddddd;
}

.table-list-item-row:nth-child(even):hover {
  background-color: #cccccc;
  border-color: #544c4c;
}

.table-list-item-row-advanced {
  width: 100%;
  background-color: #fafafa;
  transition-duration: 0.1s;
  border-left: solid 10px #fafafa;
  border-right: solid 10px #fafafa;
  .table-list-item-cell {

  }
  .table-list-item-cell-action {
    cursor: pointer;
  }
  .table-list-item-cell-action:hover {
    text-decoration: underline;
  }
}

.table-list-item-row-advanced:hover {
  background-color: #eaeaea;
  border-color: #544c4c;
}

.table-list-item-row-advanced:nth-child(4n) {
  background-color: #dddddd;
  border-bottom: solid 2px #544c4c;
  border-left: solid 10px #dddddd;
  border-right: solid 10px #dddddd;
}
.table-list-item-row-advanced:nth-child(4n+3) {
  background-color: #dddddd;
  border-top: solid 2px #544c4c;
  border-left: solid 10px #dddddd;
  border-right: solid 10px #dddddd;
}

.table-list-item-row-advanced:nth-child(4n):hover {
  background-color: #cccccc;
  border-color: #544c4c;
}

.table-list-item-row-advanced:nth-child(4n+3):hover {
  background-color: #cccccc;
  border-color: #544c4c;
}

.table-list-item-row-last-in-group {
  @extend .table-list-item-row;
  border-bottom: solid 2px #544c4c;
}

.table-list-item-row-header {
  position: sticky;
  background-color: #fafafa;
  top: 0;
  width: 100%;
  border-color: #fafafa;
  .table-list-item-cell-header {
    font-weight: 700;
  }
  .table-list-item-cell-header-action {
    cursor: pointer;
  }
  .table-list-item-cell-header-action:hover {
    text-decoration: underline;
  }
}

.table-list-item-row-header:hover {
  border-color: #eaeaea;
}

.table-action-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow-x: scroll;
  padding-top: 4px;
  padding-bottom: 4px;
  .table-action-list-item {
    .table-action-list-item-entry {
      min-width: 200px;
      padding-bottom: 4px;
      padding-top: 4px;
    }
    .table-action-list-item-entry-action {
      @extend .table-action-list-item-entry;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}


.sectioned-list-item {
  /*width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;*/
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: $sizeBorderRadiusHuge;
  background-color: #eeeeee;
  //background-color: #ffffff;
  padding-bottom: 10px;
  border: dashed 2px #333;

  .sectioned-list-item-card {
    /*border-radius: $sizeBorderRadiusHuge;
    width: 100%;
    background-color: #eeeeee;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;*/
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .list-item-section {
      flex: 1;
      width: 33%;
      padding: 10px;
      padding-left: 22px;
      .prio-status {
        padding: 4px 8px;
        margin-left: 10px;
        border-radius: $sizeBorderRadiusSmall;
        color: #ffffff;
        font-size: $sizeFontSmall;
      }
    }

    .list-item-section-with-button {
      flex: 1;
      width: 33%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .item-action-button {

        padding: 10px 10px;

        .item-action-button-inside {
          transition-duration: $transitionDurationNormal;
          font-size: $sizeFontSmall;
          color: #333;
          padding: 12px;
          cursor: pointer;
          text-align: center;
          border-radius: $sizeBorderRadiusBig;
        }

        .item-action-button-inside:hover {
          background-color: $colorMainDiffused08;
        }

      }

      .inside-list-item-section {
        width: 100%;
        padding: 10px 10px;
      }
    }
  }

}

.sectioned-list-item:nth-child(even) {
  //background-color: #ddd;
  .sectioned-list-item-card {

  }
  /*background-color: #e5e3e2;*/
}

.sectioned-list-item-compact {
  @extend .sectioned-list-item;
  padding-bottom: 0px;
  .sectioned-list-item-card {
    border-radius: 0;
  }
}

.grid-list-item {
  width: 25%;
  padding-left: 20px;
  margin-bottom: 20px;

  .inside-card {
    width: 100%;
    border-radius: $sizeBorderRadiusHuge;

    background-color: #fff;
    border: dashed 2px #333;

    //background-color: #eeeeee;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    transition-duration: $transitionDurationAnimationLong;
    .internal-container {
      padding: 10px 20px;
      padding-bottom: 0;
    }

    .item-action-button {

      padding: 10px 10px;

      .item-action-button-inside {
        transition-duration: $transitionDurationNormal;
        font-size: $sizeFontSmall;
        color: #333;
        padding: 12px;
        cursor: pointer;
        text-align: center;
        border-radius: $sizeBorderRadiusBig;
      }

      .item-action-button-inside:hover {
        background-color: $colorMainDiffused08;
      }

    }
  }
}

.grid-list-item-wrapped {
  @extend .grid-list-item;
  width: fit-content;
  flex: 1;
}

.grid-list-item-empty {
  width: 25%;
  background-color: transparent;
}

.priority-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  .priority-bubble-no {
    width: 20px;
    height: 20px;
    margin: 4px;
    border-radius: 100px;
    border: solid 2px $colorMain;
  }

  .priority-bubble-yes {
    width: 20px;
    height: 20px;
    margin: 4px;
    border-radius: 100px;
    background-color: $colorAccent;
  }
}

.internal-toolbar {
  width: 100%;
  padding: 14px 20px;
  padding-bottom: 10px;
  background-color: #fafafa;
  z-index: 20;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);

  .internal-toolbar-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 4px;

    .internal-toolbar-left-container {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;

      .internal-toolbar-page-title {
        padding-left: 10px;
        color: $colorWhite;
        font-size: $sizeFontNormal;
      }

      .toolbar-search-input {
        /*flex: 1;*/
        margin-left: 10px;
        padding: 6px 12px;
        color: $colorMainDarkOther;
        font-size: $sizeFontNormal;
        border-radius: 4px;
        background-color: $colorMainDiffused08;
        transition-duration: $transitionDurationLong;
        width: 240px;
      }
      .toolbar-search-input:focus {
        flex: 1;
        width: inherit;
      }
    }

    .internal-toolbar-right-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 0;
      padding-left: 10px;

      .internal-toolbar-m-button-deviz:hover {
        background-color: #333;
      }
    }
  }

  .internal-toolbar-filter-text-display {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 6px;
    .internal-toolbar-filter-text-description {
      color: #666;
      font-size: $sizeFontSmall;
      padding-right: 4px;
      margin-bottom: 4px;
    }

    .internal-toolbar-filter-text-entry {
      font-size: $sizeFontSmall;
      margin-right: 4px;
      margin-bottom: 4px;
      padding: 2px 8px;
      color: $white;
      background-color: rgba(4, 64, 23, 0.6);
      border-radius: 4px;
    }
  }
}

.scrollable-page {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: scroll;
}

