/* ============================ */
/*        Sidebar Menu          */
/* ============================ */

$sidebarTransition: all 0.3s ease 0s;

.sidebar-menu {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  height: 100vh;
  width: $drawerOpenSize;
  overflow: hidden;
  background: theme-color-main(general);
  box-shadow: 2px 0 32px rgba(0, 0, 0, 0.05);
  -webkit-transition: $sidebarTransition;
  transition: $sidebarTransition;
  /* width */
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.2);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  &.light-sidebar {
    background: #fff;
    .metismenu > li:hover > a,
    .metismenu > li.mm-active > a i,
    .metismenu > li.mm-active > a svg,
    .metismenu > li.mm-active > a {
      color: theme-color-main(secondary);
      background: #f7f7fa;
    }
    i {
      color: #999;
    }
    .metismenu li:hover > a i,
    .metismenu li:hover > a,
    .metismenu li.mm-active > a,
    .metismenu li.mm-active > a i {
      color: theme-color-main(secondary);
    }
    .sidebar-header {
      padding: 19px 32px 20px;
      background: #fff;
      border-bottom: 1px solid #eaeaea;
    }
  }
  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: theme-color-main(general);
    a {
      display: inline-block;
      width: 100%;
      /*border-radius: $btn-border-radius;
      background-color: theme-color-main(general);*/
      span {
        @media (min-width: 768px) and (max-width: 1364px) {
          display: block;
        }
      }
    }
  }
  .main-menu {
    height: calc(100% - 100px);
    overflow: hidden;
    padding-top: 20px;
    -webkit-transition: $sidebarTransition;
    transition: $sidebarTransition;
    .menu-inner {
      /*overflow-y: scroll;*/
      height: 100%;
    }
  }
  .sidebar-header {
    padding: 19px 32px 20px;
    background: theme-color-main(general);
    border-bottom: 1px solid #3c4256;
  }
}
.slimScrollBar {
  background: theme-color-main(secondary) !important;
  opacity: 0.1 !important;
}

/* ============================ */
/*        Sidebar Collapsed     */
/* ============================ */

.sidebar_collapsed {
  .sidebar-menu {
    left: -$drawerDifferenceSize;
  }
  .nav-btn {

  }
}

/* ============================ */
/*        Metis Menu            */
/* ============================ */
.metismenu {
  li {
    a {
      position: relative;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      color: #b5b4b4;
      font-size: 15px;
      padding: 10px;
      letter-spacing: 0;
      font-weight: 400;
      margin-bottom: 2px;
      span {
        margin-right: 20px;
      }
      /*&:after {
        position: absolute;
        content: "\f107";
        font-family: fontawesome;
        right: 15px;
        top: 6px;
        color: #b5b4b4;
        font-size: 20px;
      }*/
      i {
        color: #999;
        -webkit-transition: $sidebarTransition;
        transition: $sidebarTransition;
        font-size: 20px;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        text-align: center;
      }
    }
  }
  /*li.mm-active > a:after {
    content: "\f106";
  }*/
}

.metismenu li a:only-child::after {
  content: "";
}

.metismenu > li > a {
  padding-left: 22px !important;
}

.metismenu li.mm-active > a,
.metismenu li:hover > a {
  color: #fff;
}

/*.metismenu li li a {
  padding: 8px 20px;
}*/

.metismenu li ul {
  padding-left: 28px;
}

.metismenu > li:hover > a,
.metismenu > li.mm-active > a svg,
.metismenu > li.mm-active > a {
  color: #fff;
  background: #363946;
}

.metismenu li:hover > a,
.metismenu li.mm-active > a {
  color: $white;
}

.metismenu li:hover > a i,
.metismenu li.mm-active > a i {
  color: #fff;
}

.metismenu li li a:after {
  top: 6px;
}

/* ============================ */
/*        End Sidebar Menu      */
/* ============================ */
